import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { css } from '@emotion/core';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import AuthorCard from '../components/AuthorCard';
import PostFullFooter from '../components/PostFullFooter';
import PostFullFooterRight from '../components/PostFullFooterRight';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }

  .poster {
    width: 50%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 50px;
  }
`;

const WSsnaip: React.FC = props => {
  return (
    <IndexLayout>
      <Helmet>
        <title>Workshop SnAIp: Kein Hexenwerk - Maschinelles Lernen in Snap! </title>
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header css={[outer, SiteHeader]}>
          <div css={inner}>
            <SiteNav />
          </div>
        </header>
        <main id="site-main" className="site-main" css={[SiteMain, outer]}>
          <article className="post page" css={[PostFull, NoImage]}>
            <PostFullHeader>
              <PostFullTitle>
                Workshop SnAIp: Kein Hexenwerk - Maschinelles Lernen in Snap!
              </PostFullTitle>
            </PostFullHeader>

            <PostFullContent className="post-full-content">
              <div className="post-content">
                <p>
                  Die gesellschaftliche Relevanz des Themas künstlichen Intelligenz nimmt in letzter
                  Zeit rasant zu. Getrieben wird diese Entwicklung vor allem durch maschinelle
                  Lernverfahren. Viele Unterrichtsansätze scheitern daran, die Blackbox des zugrunde
                  liegenden KI-Frameworks aufzubrechen. Für die Schülerinnen und Schüler bedeutet
                  dies typischerweise, dass es keine Chance gibt, zu verstehen, wie der "Computer
                  lernt". In diesem Workshop haben Sie die Möglichkeit, in die Blackbox zu blicken,
                  zu erfahren, wie Maschinelles Lernen in einer visuellen Programmiersprache
                  umgesetzt werden kann und Materialien für den Unterricht mitzunehmen.{' '}
                </p>

                <ul>
                  <li>
                    <a href={props.data.ABshort.publicURL}>Arbeitsblatt für den Workshop</a>
                  </li>
                  <li>
                    <Link to="/proj-ml-uebersicht/">
                      Übersicht über die verschiedenen Paradigmen maschinellen Lernens
                    </Link>
                  </li>
                </ul>
                <h2>Part A: Reinforcement Learning</h2>
                <Img className="poster" fluid={props.data.partA.childImageSharp.fluid} alt="" />

                <ul>
                  <li>
                    <Link to="/proj-snaip-A/">
                      Alle Materialien und Erläuterungen für den Unterrichtseinsatz
                    </Link>
                  </li>
                  <li>
                    Krokodilschach:{' '}
                    <a href="https://www.stefanseegerer.de/schlag-das-krokodil/">Online-Variante</a>
                  </li>
                  <li>
                    Bananenjagd-Puzzle: <a href="https://bit.ly/snaip-bananapuzzle-de">Vorlage</a>
                  </li>
                </ul>
                <p></p>
                <h2>Part B: Unsupervised Learning</h2>
                <Img className="poster" fluid={props.data.partB.childImageSharp.fluid} alt="" />

                <ol>
                  <li>
                    <Link to="/proj-snaip-B/">
                      Alle Materialien und Erläuterungen für den Unterrichtseinsatz
                    </Link>
                  </li>
                  <li>
                    Goldrausch:{' '}
                    <a href="https://www.stefanseegerer.de/snaip-goldrush/"> Online-Variante</a>
                  </li>
                  <li>
                    Gold Rush in Snap!: <a href="https://bit.ly/snaip-goldrush-de">Vorlage</a>
                  </li>
                </ol>
              </div>
            </PostFullContent>
          </article>
          {props.data.allAuthorYaml.edges.map((element, index) => {
            return (
              <PostFullFooter key={index}>
                <AuthorCard author={element.node} />
                <PostFullFooterRight authorId={element.node.id} />
              </PostFullFooter>
            );
          })}
        </main>
        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};

export default WSsnaip;

export const query = graphql`
  query {
    partA: file(relativePath: { eq: "img/snaip/SnAIp-Poster-A.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    partB: file(relativePath: { eq: "img/snaip/SnAIp-Poster-B.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    abA: file(relativePath: { eq: "files/snaip/Part-A.pdf" }) {
      publicURL
    }
    abB: file(relativePath: { eq: "files/snaip/Part-B.pdf" }) {
      publicURL
    }
    ABshort: file(relativePath: { eq: "files/snaip/snaip-workshop-complete-de.pdf" }) {
      publicURL
    }
    allAuthorYaml(filter: { id: { in: ["stefan", "tilman", "sven"] } }) {
      edges {
        node {
          id
          name
          twitter
          website
          bio
          avatar {
            children {
              ... on ImageSharp {
                fixed(quality: 90) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;
